<template>
  <div class="Temporal_container:right">
    <div class="Temporal_container:right-title">{{ this.paramname }}</div>
    <div class="Temporal_container:right-graph">
      <div id="graph" ref="graph"></div>
    </div>
  </div>
</template>
<script>
import * as d3 from "d3";
import axios from "axios";
export default {
  data() {
    return {
      playerdata: [],
      paramname: null,
      counter: 0,
      counterFetch: 0,
    };
  },
  props: {
    param: {
      type: Array,
      reuired: false,
    },
    paramdata: {
      type: Array,
      required: true,
    },
    parname: {
      type: String,
      reuired: true,
    },
    multiple: {
      type: Boolean,
    },
  },
  watch: {
    paramdata(val) {
      // console.log(val);
      this.counter = 0;
      this.counterFetch = 0;
      for (let i = 0; i < val.length; i++) {
        if (val[i].jerseyNum != "") {
          this.counter = this.counter + 1;
          this.fetchData(
            this.paramdata[i].isOppositeTeam,
            this.paramdata[i].jerseyNum,
            this.paramdata[i].color,
            this.paramname
          );
        }
      }
    },
    graphParameter(val) {
      this.paramname = val;
      this.playerdata = [];
      this.counter = 0;
      this.counterFetch = 0;
      for (let i = 0; i < this.paramdata.length; i++) {
        if (this.paramdata[i].jerseyNum != "") {
          console.log(this.paramdata[i]);
          this.counter = this.counter + 1;
          this.fetchData(
            this.paramdata[i].isOppositeTeam,
            this.paramdata[i].jerseyNum,
            this.paramdata[i].color,
            this.paramname
          );
        }
      }
    },
  },
  components: {},
  computed: {
    player() {
      return this.$store.state.field.card.data;
    },
    cardactive() {
      return this.$store.state.field.card.active;
    },
    idgame() {
      return this.$store.state.idgame;
    },
    storeIdgame() {
      return this.$store.state.storeIdgame;
    },
    graphParameter() {
      return this.$store.state.faceto.graphParameter;
    },
  },
  headerConfig() {
    return this.$store.getters.getHeaderConfig;
  },
  methods: {
    fetchData(isOppositeTeam, jerseyNum, color, name) {
      // console.log(
      //   isOppositeTeam + " - " + jerseyNum + " - " + color + " - " + name
      // );
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/graphTrend?liveId=" +
            (this.idgame != null ? this.idgame : this.storeIdgame) +
            "&isOppositeTeam=" +
            isOppositeTeam +
            "&jerseyNum=" +
            jerseyNum +
            "&kpi=" +
            encodeURIComponent(name) +
            "&width=700&height=410",
          this.headerConfig
        )
        .then((response) => {
          let data = {
            data: response.data,
            color: color ? color : "#00FC77",
          };
          this.playerdata.push(data);
          this.counterFetch = this.counterFetch + 1;
//          console.log(color + " - " + this.counter + " - " + this.counterFetch);
          if (this.counter == this.counterFetch) {
            this.graph(this.playerdata);
          }
        })
        .catch((error) => {
          console.error("Error during tech data for timeline" + error);
        });
    },
    graph(playerdata) {
      const graph = this.$refs.graph;

      // I dati in arrivo sono le medie per slot ==> La trasformo in media progressiva se previsto da parametro Draw_type
      for (let x = 0; x < playerdata.length; x++) {
        if (playerdata[x].data[0].drawtype == "M") {
          let cumSum = 0;
          //        playerdata[x].data.reduce( (prev, curr,i) =>  mean_array[i] = (i == 0) ? curr : (prev*i + curr)/(i+1) , 0 );
          playerdata[x].data = playerdata[x].data.map((e, i) => {
            cumSum += parseFloat(e.valy);
            return { valx: e.valx, valy: cumSum / (i + 1) };
          });
        }
      }

      d3.select("#graph")
        .selectAll("svg")
        .remove();

      var origData = [];
      var data = [];
      let val = "";

      let minValx = 9999999999;
      let maxValx = -9999999999;
      let minValy = 9999999999;
      let maxValy = -9999999999;

      for (let x = 0; x < playerdata.length; x++) {
        val = playerdata[x].data.map((e) => {
          if (parseInt(e.valx) < parseInt(minValx)) minValx = parseInt(e.valx);
          if (parseInt(e.valx) > parseInt(maxValx)) maxValx = parseInt(e.valx);
          if (parseFloat(e.valy) < parseFloat(minValy))
            minValy = parseFloat(e.valy);
          if (parseFloat(e.valy) > parseFloat(maxValy))
            maxValy = parseFloat(e.valy);

          return { x: e.valx, y: e.valy };
        });
        origData.push(val);
      }

      var w = 700,
        h = 410,
        pad = 25,
        shadowX = 10,
        shadowY = 10,
        ratioX = (maxValx - minValx) / (w - 2 * pad),
        ratioY = (maxValy - minValy) / (h - 2 * pad),
        deltaPadX = 0,
        deltaPadY = 0;
      // Formatta i valori sulla dimensione del grafico
      for (const item of origData) {
        var sVal = item.map((point) => {
          return {
            x: (point.x - minValx) / ratioX,
            y: h - (point.y - minValy) / ratioY - 2 * pad - deltaPadY,
          };
        });
        data.push(sVal);
      }
      var svg = d3
        .select("#graph")
        .append("svg")
        .attr("height", h)
        .attr("width", w);

      var valueline = d3
        .line("basis")
        .x(function(d) {
          return d.x + pad;
        })
        .y(function(d) {
          return d.y + pad;
        })
        .curve(d3.curveBasis);

      var shadowline = d3
        .line("basis")
        .x(function(d) {
          return d.x + pad + shadowX;
        })
        .y(function(d) {
          return d.y + pad + shadowY;
        })
        .curve(d3.curveBasis);

      var xScale = d3
        .scaleLinear()
        .domain([Math.floor(minValx / 60000), Math.floor(maxValx / 60000)])
        .range([pad + deltaPadX, w - pad]);

      var yScale = d3
        .scaleLinear()
        .domain([minValy, maxValy])
        .range([h - pad - deltaPadY, pad]);

      var Grid = d3
        .scaleLinear()
        .domain([Math.floor(minValx / 60000), Math.floor(maxValx / 60000)])
        .range([pad + deltaPadX, w - pad]);

      var xAxis = d3.axisBottom(xScale);
      var yAxis = d3.axisLeft(yScale);

      var yScaleGrid = d3
        .axisBottom()
        .tickFormat("")
        .tickSize(-325)
        .scale(Grid);

      var defs = svg.append("defs");
      //line
      var filter = defs.append("filter").attr("id", "glow");
      filter
        .append("feGaussianBlur")
        .attr("stdDeviation", "5.5")
        .attr("result", "coloredBlur");
      var feMerge = filter.append("feMerge");
      feMerge.append("feMergeNode").attr("in", "coloredBlur");
      feMerge.append("feMergeNode").attr("in", "SourceGraphic");

      //shodow
      var filterShadow = defs.append("filter").attr("id", "glowShadow");
      filterShadow
        .append("feGaussianBlur")
        .attr("stdDeviation", "10")
        .attr("result", "coloredBlur");
      var feMergeShadow = filterShadow.append("feMerge");
      feMergeShadow.append("feMergeNode").attr("in", "coloredBlur");
      feMergeShadow.append("feMergeNode").attr("in", "SourceGraphic");

      svg
        .append("g")
        .attr("class", "axis")
        .attr("transform", "translate(0," + (h - pad) + ")")
        .call(xAxis);

      svg
        .append("g")
        .attr("class", "grid")
        .attr("transform", "translate(0," + (h - pad) + ")")
        .style("opacity", 0.3)
        .call(yScaleGrid);

      svg
        .append("g")
        .attr("class", "axis")
        .attr("transform", "translate(" + pad + ", 0)")
        .call(yAxis);

      for (let n = 0; n < data.length; n++) {
//        console.log(playerdata[n].color);
        svg
          .append("path")
          .attr("class", "line")
          .style("filter", "url(#glowShadow)")
          .style("opacity", 0.5)
          .attr("d", shadowline(data[n]))
          .attr("stroke", "#000")
          .attr("stroke-width", 7)
          .attr("fill", "none");

        svg
          .append("path")
          .attr("class", "line")
          .style("filter", "url(#glow)")
          .attr("d", valueline(data[n]))
          .attr("stroke", playerdata[n].color)
          .attr("stroke-width", 4)
          .attr("fill", "none");

        // svg
        //   .append("path")
        //   .attr("class", "line")
        //   .attr("d", valueline(data))
        //   .attr("stroke", "#000")
        //   .attr("stroke-width", 5)
        //   .attr("fill", "none");
        /*
        var circ = svg
          .selectAll("circle")
          .data(data[n])
          .enter()
          .append("circle")
          .attr("cx", function(d) {
            return data[n][data[n].length - 1].x;
          })
          .attr("cy", function(d) {
            return data[n][data[n].length - 1].y;
          })
          .attr("r", function(d) {
            return 9;
          })
          .attr("fill", "white")
          .attr("opacity", 0.5);

        var circShadow = svg
          .selectAll("circleShadow")
          .data(data[n])
          .enter()
          .append("circle")
          .attr("cx", function(d) {
            return data[n][data[n].length - 1].x + 10;
          })
          .attr("cy", function(d) {
            return data[n][data[n].length - 1].y + shadow;
          })
          .attr("r", function(d) {
            return 9;
          })
          .attr("fill", "0a0a0a")
          .style("opacity", 0.5);

        var circOut = svg
          .selectAll("circleOut")
          .data(data[n])
          .enter()
          .append("circle")
          .attr("cx", function(d) {
            return data[n][data[n].length - 1].x;
          })
          .attr("cy", function(d) {
            return data[n][data[n].length - 1].y;
          })
          .attr("r", function(d) {
            return 14;
          })
          .attr("fill", "transparent")
          .attr("stroke", "#00fc77")
          .attr("stroke-width", 1)
          .attr("opacity", 0.5);
          */
      } // clicare tutti gli elementi passati
    },
  },
  created() {},
  mounted() {
    // if (!this.parname) {
    //   this.paramname = this.param[0].name;
    // } else {
    //   this.paramname = this.parname;
    // }
    // console.log(this.paramdata);
    this.paramname = this.graphParameter;
    this.counter = 0;
    this.counterFetch = 0;
    for (let i = 0; i < this.paramdata.length; i++) {
      if (this.paramdata[i].jerseyNum != "") {
        console.log(this.paramdata[i]);
        this.counter = this.counter + 1;
        this.fetchData(
          this.paramdata[i].isOppositeTeam,
          this.paramdata[i].jerseyNum,
          this.paramdata[i].color,
          this.paramname
        );
      }
    }
  },
};
</script>

<style lang="scss">
.Temporal {
  &_container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    width: 1150px;
    &\:right {
      width: 100%;
      &-title {
        text-align: left;
        padding-bottom: 30px;
        padding-left: 74px;
        font-weight: bold;
      }
      &-graph {
      }
    }
  }
}
#graph {
  display: flex;
  justify-self: flex-start;
  path.domain {
    opacity: 0;
  }
  .axis {
    line {
      opacity: 0;
    }
  }
  .grid {
    .tick {
      &:nth-child(1) {
        background-color: #fff;
        line {
          opacity: 0;
        }
      }
    }
  }
}
</style>
