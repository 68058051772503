var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Temporal_container:right" }, [
    _c("div", { staticClass: "Temporal_container:right-title" }, [
      _vm._v(_vm._s(this.paramname))
    ]),
    _c("div", { staticClass: "Temporal_container:right-graph" }, [
      _c("div", { ref: "graph", attrs: { id: "graph" } })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }